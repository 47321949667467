import { inject, InjectionToken } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Tenant } from '@appFunctions/models';
import { merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, shareReplay, take } from 'rxjs/operators';


export interface IMultitenancyService {
  currentDomainName(): Observable<string>;
  getTenant(): Observable<Tenant>;
}

export const MULTITENANCY_SERVICE_TOKEN = new InjectionToken<IMultitenancyService>('MULTITENANCY_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () => new DefaultMultitenancyService(inject(Router), inject(ActivatedRoute)),
});

export class DefaultMultitenancyService implements IMultitenancyService {
  navigation$: Observable<void>;
  constructor(private readonly router: Router, private readonly route: ActivatedRoute) {
    // ActivatedRoute only helps on intial load because some observables might be subscribed in view using | async after
    // router.events have already fired. Subsequent router events will be captured by router.events.
    this.navigation$ = merge(
      this.route.paramMap.pipe(take(1)),
      this.router.events.pipe(filter(x => x instanceof NavigationEnd))
    ).pipe(
      mapTo(void(0)),
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    );
  }

  currentDomainName(): Observable<string> {
    return this.navigation$.pipe(
      map(() => window.location.host),
      distinctUntilChanged()
    );
  }

  getTenant(): Observable<Tenant> {
    return this.navigation$.pipe(
      map(() => {
        let route = this.router.routerState.root;
        while (route.firstChild && !!route.component) {
          route = route.firstChild;
        }
        return route.snapshot.data.tenant;
      }),
      distinctUntilChanged()
    );
  }
}

export class RouteMultitenancyService implements IMultitenancyService {
  navigation$: Observable<void>;
  constructor(private readonly router: Router, private readonly route: ActivatedRoute) {
    // ActivatedRoute only helps on intial load because some observables might be subscribed in view using | async after
    // router.events have already fired. Subsequent router events will be captured by router.events.
    this.navigation$ = merge(
      this.route.paramMap.pipe(take(1)),
      this.router.events.pipe(filter(x => x instanceof NavigationEnd))
    ).pipe(
      mapTo(void(0)),
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    );
  }

  currentDomainName(): Observable<string> {
    return this.navigation$.pipe(
      map(() => {
        let route = this.router.routerState.root;
        while (route.firstChild && !!route.component) {
          route = route.firstChild;
        }
        return route.snapshot.paramMap.get('tenant_name');
      }),
      distinctUntilChanged()
    );
  }

  getTenant(): Observable<Tenant> {
    return this.navigation$.pipe(
      map(() => {
        let route = this.router.routerState.root;
        while (route.firstChild && !!route.component) {
          route = route.firstChild;
        }
        return route.snapshot.data.tenant;
      }),
      distinctUntilChanged()
    );
  }
}
