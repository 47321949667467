import {NgModuleFactory, Type} from '@angular/core';
import {LazyWidgetCollection} from '@appCore/lazy/types';

export const lazyWidgets: {name: string; loadChildren: () => Promise<NgModuleFactory<any> | Type<any>>}[] = [
  {
    name: 'lazy-dialog-employee-accept-invite',
    loadChildren: () => import('./doorcaptain/components/employee-accept-invite-dialog/employee-accept-invite.module')
      .then(m => m.EmployeeAcceptInviteModule)
  },
  {
    name: 'lazy-dialog-employee-invite',
    loadChildren: () => import('./doorcaptain/components/employee-invite-dialog/employee-invite.module')
      .then(m => m.EmployeeInviteModule)
  },
  {
    name: 'lazy-dialog-biz-dealership-new-appointment',
    loadChildren: () => import('./doorcaptain/components/biz-dealership-new-appointment-dialog/biz-dealership-new-appointment.module')
      .then(m => m.BizDealershipNewAppointmentModule)
  },
  {
    name: 'lazy-dialog-biz-dealership-vehicle-pickup',
    loadChildren: () => import('./doorcaptain/components/biz-dealership-vehicle-pickup-dialog/biz-dealership-vehicle-pickup-dialog.module')
      .then(m => m.BizDealershipPickupDialogModule)
  },
  {
    name: 'lazy-dialog-add-location',
    loadChildren: () => import('./doorcaptain/components/add-location-dialog/add-location-dialog.module')
      .then(m => m.AddLocationDialogModule)
  },
  {
    name: 'lazy-dialog-confirmation',
    loadChildren: () => import('./core/confirmation-dialog/confirmation-dialog.module')
      .then(m => m.ConfirmationDialogModule)
  },
  {
    name: 'lazy-eula-required-dialog',
    loadChildren: () => import('./doorcaptain/components/eula-required-dialog/eula-required-dialog.module')
      .then(m => m.EulaRequiredDialogModule)
  },
  {
    name: 'lazy-landing-on-my-way-launcher',
    loadChildren: () => import('./doorcaptain/views/landing-on-my-way/launcher/launcher.module')
      .then(m => m.LauncherModule)
  },
  {
    name: 'lazy-landing-on-my-way-on-site-identification',
    loadChildren: () => import('./doorcaptain/views/landing-on-my-way/on-site-identification/on-site-identification.module')
      .then(m => m.OnSiteIdentificationModule)
  },
  {
    name: 'lazy-landing-on-my-way-reschedule-appointment',
    loadChildren: () => import('./doorcaptain/views/landing-on-my-way/reschedule-appointment/reschedule-appointment.module')
      .then(m => m.RescheduleAppointmentModule)
  },
  {
    name: 'lazy-form-add-edit-user',
    loadChildren: () => import('./doorcaptain/views/settings/tenant/manage-locations/location-settings/add-edit-user/add-edit-user.module')
      .then(m => m.AddEditUserModule)
  },
];

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function lazyFactory(): LazyWidgetCollection {
  const result = {};
  for (const w of lazyWidgets) {
    result[w.name] = w.loadChildren;
  }
  return result;
}
