import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, first, switchMap } from 'rxjs/operators';
import { EMPTY, Observable, of as observableOf } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Tenant } from '@appFunctions/models';

@Injectable({ providedIn: 'root' })
export class TenantResolver implements Resolve<any> {
  constructor(private readonly afs: AngularFirestore,
    private readonly router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Tenant> | Observable<never> {
    const name = route.paramMap.get('tenant_name');
    const tenantId = name.split('-').pop();

    const params = { url: state.url };
    return this.afs.doc<Tenant>(`/modules/doorcaptain/tenants/${tenantId}`).valueChanges().pipe(
      first(),
      switchMap((tenant?: Tenant) => {
        if (!tenant) {
          this.router.navigate(['/404'], { queryParams: params });
          return EMPTY;
        }
        return observableOf(tenant);
      }),
      catchError(() => {
        this.router.navigate(['/500'], { queryParams: params });
        return EMPTY;
      }));
  }
}
