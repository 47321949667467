import {BrowserModule} from '@angular/platform-browser';
import {inject, Injector, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFireModule} from '@angular/fire';
import {ActivatedRoute, Router} from '@angular/router';

import {
  AngularFirestore, AngularFirestoreModule,
  SETTINGS as FIRESTORE_SETTINGS,
  USE_EMULATOR as USE_FIRESTORE_EMULATOR
} from '@angular/fire/firestore';
import {USE_EMULATOR as USE_DATABASE_EMULATOR} from '@angular/fire/database';
import {AngularFireAuth, USE_EMULATOR as USE_AUTH_EMULATOR} from '@angular/fire/auth';
import {USE_EMULATOR as USE_FUNCTIONS_EMULATOR} from '@angular/fire/functions';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment as environmentConfig} from '@appFunctions/env.generated';
import {MULTITENANCY_SERVICE_TOKEN, RouteMultitenancyService} from '@appCore/security/multitenancy.service';
import {FirebaseTokenModule, FIREBASE_TOKEN_INTERCEPTOR_WHITELIST_HANDLER} from '@appCore/interceptors/firebase-token.interceptor';
import {ADDRESS_COMPONENT_CONFIG} from '@appCore/forms/address/configuration';

import '../environments/firebase-initialization';
import {FIREBASE_PIPES_FETCH_USER_TOKEN} from '@appCore/pipes/firebase/injection-tokens';
import {UserProxyService} from '@appCore/client-proxies/user-proxy.service';

import {applyMemoizeBehavior} from '@appCore/behaviors/memoize-behavior';
import {merge} from 'rxjs';
import {MatNativeDateModule} from '@angular/material/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {LAZY_WIDGETS} from '@appCore/lazy/types';
import {lazyFactory} from './lazy-widgets';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {PermissionProxyService} from '@appCore/client-proxies/permission-proxy.service';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AppVersionProxyService} from '@appCore/client-proxies/app-version.service';
import {EulaRequiredDialogService} from '@appComponents/eula-required-dialog/eula-required-dialog.service';

const additionalProviders = [];
if (environmentConfig.environment === 'ci') {
  additionalProviders.push(...[{
    provide: USE_AUTH_EMULATOR, useValue: ['localhost', 9099]
  },
  {
    provide: USE_FUNCTIONS_EMULATOR, useValue: ['localhost', 5001]
  },
  {
    provide: USE_FIRESTORE_EMULATOR, useValue: ['localhost', 8080]
  },
  {
    provide: USE_DATABASE_EMULATOR, useValue: ['localhost', 9000]
  }]);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environmentConfig.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    FirebaseTokenModule,
    MatNativeDateModule,
    MatIconModule,
    SnotifyModule,
  ],
  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'standard'}},
    {
      provide: FIRESTORE_SETTINGS, useValue: {ignoreUndefinedProperties: true, merge: true},
    }, {
      provide: MULTITENANCY_SERVICE_TOKEN, useFactory: () => new RouteMultitenancyService(inject(Router), inject(ActivatedRoute))
    }, {
      provide: FIREBASE_TOKEN_INTERCEPTOR_WHITELIST_HANDLER, useValue: (url) => !/^(?:[a-zA-Z]+:)?\/\//i.test(url) ||
        url.startsWith(environmentConfig.cloudFunctionOrigin),
    }, {
      provide: ADDRESS_COMPONENT_CONFIG, useValue: {
        placesApi: `${environmentConfig.cloudFunctionOrigin}/rpc/v1/thirdparties/google/places`,
        timezoneApi: `${environmentConfig.cloudFunctionOrigin}/rpc/v1/thirdparties/google/timezone`,
        geoCodeByAddressApi: `${environmentConfig.cloudFunctionOrigin}/rpc/v1/thirdparties/google/geocode`,
        geoCodeByIpApi: `${environmentConfig.appengineOrigin}/geoCodeByIpAddress`,
      },
    },
    {
      provide: UserProxyService,
      useFactory: (fbAuth: AngularFireAuth, db: AngularFirestore, injector: Injector) => {
        const multitenancyService = injector.get(MULTITENANCY_SERVICE_TOKEN);
        const service = new UserProxyService(db, fbAuth);
        const invalidateCache = merge([fbAuth.user, multitenancyService.getTenant()]);
        service.doc = applyMemoizeBehavior(service, service.doc, invalidateCache);
        service.docWithPii = applyMemoizeBehavior(service, service.docWithPii, invalidateCache);
        return service;
      },
      deps: [AngularFireAuth, AngularFirestore, Injector],
    },
    {
      provide: PermissionProxyService,
      useFactory: (fbAuth: AngularFireAuth, db: AngularFirestore) => {
        const service = new PermissionProxyService(fbAuth, db);
        const invalidateCache = merge([fbAuth.user]);
        service.hasTenantPermission = applyMemoizeBehavior(service, service.hasTenantPermission, invalidateCache);
        service.hasTenantLocationPermission = applyMemoizeBehavior(service, service.hasTenantLocationPermission, invalidateCache);
        return service;
      },
      deps: [AngularFireAuth, AngularFirestore, Injector],
    },
    {
      provide: FIREBASE_PIPES_FETCH_USER_TOKEN,
      useFactory: (userProxy: UserProxyService) => ({
        doc: userProxy.docWithPii.bind(userProxy)
      }),
      deps: [UserProxyService]
    },
    {provide: LAZY_WIDGETS, useFactory: lazyFactory},
    {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,
    ...additionalProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private readonly matIconRegistry: MatIconRegistry,
    private readonly appVersionProxyService: AppVersionProxyService,
    private readonly eulaRequiredDialogService: EulaRequiredDialogService) {
    this.matIconRegistry.registerFontClassAlias('doorcaptain', 'fas');

    if (environmentConfig.environment === 'ci') {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.appVersionProxyService.refreshOnUpdate().subscribe(() => { });
    }

    this.eulaRequiredDialogService.eulaWatch();
  }
}
