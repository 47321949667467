import { ComponentRef, InjectionToken, NgModuleFactory, Type } from '@angular/core';

export interface LazyWidgetCollection {
  [key: string]: () => Promise<NgModuleFactory<any> | Type<any>>;
}

export const LAZY_WIDGETS = new InjectionToken<LazyWidgetCollection>('LAZY_WIDGETS', {
  providedIn: 'root',
  factory: () => ({}),
});

export interface LazyComponentInitializer {
  lazyHostKey: string;
  lazyHostInit: (comp: ComponentRef<unknown>) => void;
}
