import { Inject, Injectable, InjectionToken, NgModule, Optional, SkipSelf } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HTTP_INTERCEPTORS, HttpClientModule
} from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, from as observableFrom, firstValueFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';

type WhiteListHandler = (url: string) => boolean;

export const FIREBASE_TOKEN_INTERCEPTOR_WHITELIST_HANDLER = new InjectionToken<WhiteListHandler>(
  'FIREBASE_TOKEN_INTERCEPTOR_WHITELIST_HANDLER', {
  providedIn: 'root',
  factory: () => () => false,
});


@Injectable()
export class FirebaseTokenInterceptor implements HttpInterceptor {

  constructor(
    @Inject(FIREBASE_TOKEN_INTERCEPTOR_WHITELIST_HANDLER) private readonly whiteListHandler: WhiteListHandler,
    private readonly afAuth: AngularFireAuth) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.whiteListHandler(request.url)) {
      return next.handle(request);
    }

    return observableFrom(firstValueFrom(this.afAuth.authState)).pipe(
      switchMap(user => {
        if (user) {
          return observableFrom(user.getIdToken(false)).pipe(
            switchMap((token) => {
              request = request.clone({
                setHeaders: {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  Authorization: `Bearer ${token}`
                }
              });
              return next.handle(request);
            })
          );
        } else {
          return next.handle(request);
        }
      })
    );
  }
}


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FirebaseTokenInterceptor,
      multi: true
    },
  ]
})
export class FirebaseTokenModule {
  constructor(
    @Optional() @SkipSelf() parentModule?: FirebaseTokenModule) {
    if (parentModule) {
      throw new Error('FirebaseTokenModule is already loaded. Import it in the root module only.');
    }
  }
}
