import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MultitenancyGuard} from '@appCore/routing/multitenancy.guard';
import {TenantResolver} from '@appCore/routing/tenant.resolver';
import {RequireSignInGuard} from '@appCore/routing/requiresignin.guard';

const routes: Routes = [
  {
    path: 'tenants/:tenant_name',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./doorcaptain/views/settings/settings-view.module').then(m => m.SettingsViewModule),
      },
      {
        path: '',
        loadChildren: () => import('./doorcaptain/views/tracking/tracking-view.module').then(m => m.TrackingViewModule),
      },
    ],
    canActivate: [RequireSignInGuard, MultitenancyGuard],
    resolve: {tenant: TenantResolver}
  },
  {
    path: '403',
    loadChildren: () => import('./doorcaptain/views/page403/page403.module').then(m => m.Page403Module)
  },
  {
    path: '500', // <!-- will only be used in a routing error.
    loadChildren: () => import('./doorcaptain/views/page500/page500.module').then(m => m.Page500Module)
  },
  {
    path: 'landing/on-my-way',
    loadChildren: () => import('./doorcaptain/views/landing-on-my-way/landing-on-my-way.module')
      .then(m => m.LandingOnMyWayModule)
  },
  {
    path: 'landing/invite-subscriber',
    loadChildren: () => import('./doorcaptain/views/landing-invite-subscriber/landing-invite-subscriber.module')
      .then(m => m.LandingInviteSubscriberModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./doorcaptain/views/signup/signup-view.module').then(m => m.SignupViewModule)
  },
  {path: 'login', redirectTo: '/?login=1', pathMatch: 'full'},
  {
    path: '',
    loadChildren: () => import('./doorcaptain/views/index/index-view.module').then(m => m.IndexViewModule)
  },
  {
    path: '**',
    loadChildren: () => import('./doorcaptain/views/page404/page404.module').then(m => m.Page404Module),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
