import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {mapTo, Observable, scan} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppVersionProxyService {
  constructor(private readonly afs: AngularFirestore) {
  }

  refreshOnUpdate(): Observable<void> {
    // https://github.com/firebase/firebase-tools/issues/594
    return this.afs.collection(`/modules/app-versions/web`).valueChanges()
      .pipe(
        scan((counter: number, value: unknown[]) => {
          if (value.length === 0) {
            return counter;
          }

          if (counter === 0) {
            counter = value.length;
            return counter;
          }

          if (counter < value.length) {
            counter = value.length;
            location.reload();
          }
          return counter;
        }, 0),
        mapTo(void (0)),
      );
  }
}
