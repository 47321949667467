/* eslint-disable space-before-function-paren */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { merge, Observable, ReplaySubject } from 'rxjs';
import { exhaustMap, startWith } from 'rxjs/operators';

// Currently this is an inmemory cache.
// eslint-disable-next-line @typescript-eslint/ban-types
export function applyMemoizeBehavior<TService extends object, TResult>(
  service: TService, action: (...x) => Observable<TResult>, invalidateCache: Observable<unknown>):
  (...x) => Observable<TResult> {
  const cache = new Map<string, ReplaySubject<TResult>>();
  const modified = function (): Observable<TResult> {
    const callerArguments = Array.from(arguments);
    const key = service.constructor.name + JSON.stringify(callerArguments);
    if (!cache.has(key)) {
      const replaySubject = new ReplaySubject<TResult>(1);
      cache.set(key, replaySubject);
      merge(invalidateCache).pipe(
        startWith(undefined),
        exhaustMap(() => {
          return action.apply(service, callerArguments) as Observable<TResult>;
        })
      ).subscribe(replaySubject);
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return cache.get(key)!;
  };
  return modified;
}
