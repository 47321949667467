import { Compiler, Inject, Injectable, Injector, NgModuleFactory } from '@angular/core';
import { LazyWidgetCollection, LAZY_WIDGETS } from './types';


@Injectable({ providedIn: 'root' })
export class LazyModuleLoaderService {

  constructor(
    private compiler: Compiler,
    private injector: Injector,
    @Inject(LAZY_WIDGETS) private lazyWidgets: LazyWidgetCollection) {
  }

  async load(name: string) {
    const ngModuleOrNgModuleFactory = await this.lazyWidgets[name]();

    let moduleFactory;
    if (ngModuleOrNgModuleFactory instanceof NgModuleFactory) {
      moduleFactory = ngModuleOrNgModuleFactory;
    } else {
      moduleFactory = await this.compiler.compileModuleAsync(ngModuleOrNgModuleFactory);
    }

    // TODO(lmeneses): cache moduleFactory or cache moduleRef?
    // take application default injector but allow overriding here as an argument.
    const moduleRef = moduleFactory.create(this.injector);

    return { moduleRef, moduleFactory };
  }
}
