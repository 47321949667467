import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate, UrlTree } from '@angular/router';
import { Tenant } from '@appFunctions/models';
import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MultitenancyGuard implements CanActivate {

  constructor(private readonly afs: AngularFirestore,
    private readonly fbAuth: AngularFireAuth,
    private readonly router: Router) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.fbAuth.authState.pipe(
      switchMap((user): Observable<boolean | UrlTree> => {
        if (!user) {
          // User is not signed in.
          // TODO(lmeneses): These should be configurable or have a matcher
          // https://stackoverflow.com/questions/48233557/angular-reference-query-params-in-redirectto
          return observableOf(this.router.parseUrl('/?login=1'));
        }

        const name = route.paramMap.get('tenant_name');
        const tenantId = name.split('-').pop();

        return this.afs.doc<Tenant>(`/modules/doorcaptain/tenants/${tenantId}`).valueChanges()
          .pipe(
            switchMap((tenant?: Tenant): Observable<boolean | UrlTree> => {
              if (!tenant) {
                // Tenant not found.
                return observableOf(this.router.parseUrl(`/signup?claim-today=${name}`));
              }

              // User does not have this tenant assigned.
              return this.afs.doc<Tenant>(`/modules/doorcaptain/users/${user.uid}/users-tenants/${tenantId}`)
                .valueChanges().pipe(
                  switchMap(x => {
                    if (!x) {
                      // User is not assigned to Tenant.
                      return observableOf(this.router.parseUrl('/?forbidden'));
                    }
                    // User is assigned to Tenant.
                    return observableOf(true);
                  }));
            }));
      }));
  }
}
