import { InjectionToken } from '@angular/core';

export interface AddressComponentConfiguration {
  placesApi: string;
  timezoneApi: string;
  geoCodeByAddressApi: string;
  geoCodeByIpApi: string;
}

export const ADDRESS_COMPONENT_CONFIG = new InjectionToken<Partial<AddressComponentConfiguration>>(
  'ADDRESS_COMPONENT_CONFIG');

