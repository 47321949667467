import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {LazyModuleLoaderService} from '@appCore/lazy/lazy-module-loader-service';
import {
  catchError, combineLatest, filter, firstValueFrom, mapTo, mergeScan,
  of as observableOf, throwError, timer
} from 'rxjs';
import {EulaStatus} from './types';
import {environment as environmentConfig} from '@appFunctions/env.generated';
import {IntegrationTestingService} from '@appCore/testing/integration-testing.service';

export {EulaStatus};

@Injectable({
  providedIn: 'root'
})
export class EulaRequiredDialogService {

  constructor(private readonly lazyLoaderService: LazyModuleLoaderService,
    private readonly afAuth: AngularFireAuth,
    private readonly http: HttpClient,
    private readonly integrationTestingService: IntegrationTestingService) { }

  eulaWatch() {
    combineLatest([this.afAuth.user, timer(0, 1000 * 60 * 1)]).pipe(
      filter(() => !this.integrationTestingService.isTestingEnabled()),
      mergeScan(async (accumulator, [user,]) => {
        if (!user) {
          return {...accumulator, signedIn: false};
        }

        if (!accumulator.hasSignedEula) {
          accumulator.hasSignedEula = await firstValueFrom(
            this.http.get(`${environmentConfig.cloudFunctionOrigin
              }/api/v1/eulas/${environmentConfig.eulaVersion
              }/users/${user.uid}`).pipe(
                mapTo(true),
                catchError((err) => {
                  if (err instanceof HttpErrorResponse && err.status === 404) {
                    return observableOf(false);
                  }
                  return throwError(() => err);
                })
              ));

          if (!accumulator.hasSignedEula) {
            let eulaStatus = EulaStatus.DECLINE;
            do {
              const {moduleRef} = await this.lazyLoaderService.load('lazy-eula-required-dialog');
              eulaStatus = await ((moduleRef.instance as any).eulaPrompt() as Promise<EulaStatus>);
              if (eulaStatus === EulaStatus.ACCEPT) {
                accumulator.hasSignedEula = true;
              }
            } while (eulaStatus === EulaStatus.DECLINE);
          }
        }

        return accumulator;
      }, {
        signedIn: false,
        hasSignedEula: false,
      }, 1)
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    ).subscribe(() => { });
  }
}
