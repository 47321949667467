import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { RoleType, TenantLocationPermissions, TenantPermissions } from '@appFunctions/app.security';
import { Observable , of as observableOf} from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class PermissionProxyService {

  constructor(
    private readonly afAuth: AngularFireAuth,
    private readonly afs: AngularFirestore,) { }


  async createRole(tenantId: string, userId: string, role: Partial<RoleType>): Promise<void> {
    await this.afs.doc<Partial<RoleType>>(
      `/modules/doorcaptain/tenants/${tenantId}/roleTypes/${role.id}`).set({
        ...role,
        createdBy: userId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      });
  }

  async updateRole(tenantId: string, userId: string, role: Partial<RoleType>): Promise<void> {
    await this.afs.doc<Partial<RoleType>>(
      `/modules/doorcaptain/tenants/${tenantId}/roleTypes/${role.id}`).update({
        ...role,
        updatedBy: userId,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
  }

  // Used by guards as resolvers resolving tenant data have not executed.
  // @see: https://github.com/angular/angular/issues/24187
  hasTenantLocationPermission(tenantId: string, locationId: string,
      permission: TenantLocationPermissions): Observable<boolean> {
    return this.afAuth.user.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs.doc<{ tenantLocationPermissions: string[] }>(
            `/modules/doorcaptain/tenants/${tenantId
            }/users-permissions/${user.uid
            }/users-permissions-locations/${locationId}`).valueChanges().pipe(
              map(registration => registration?.tenantLocationPermissions?.includes(permission.toString())),
              take(1)
            );
        } else {
          return observableOf(false);
        }
      })
    );
  }

  // Used by guards as resolvers resolving tenant data have not executed.
  // @see: https://github.com/angular/angular/issues/24187
  hasTenantPermission(tenantId: string, permission: TenantPermissions): Observable<boolean> {
    return this.afAuth.user.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs.doc<{ tenantPermissions: string[] }>(
            `/modules/doorcaptain/tenants/${tenantId
            }/users-permissions/${user.uid}`).valueChanges().pipe(
              map(registration => registration?.tenantPermissions?.includes(permission.toString())),
              take(1)
            );
        } else {
          return observableOf(false);
        }
      })
    );
  }

  allTenantRoles(tenantId: string): Observable<RoleType[]> {
    return this.afs.collection<RoleType>(
      `/modules/doorcaptain/tenants/${tenantId}/roleTypes`).valueChanges();
  }
}
