import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IntegrationTestingService {
  isTestingEnabled(): boolean {
    return window.localStorage.getItem('INTEGRATION_TESTING_ENABLED') === 'true';
  }
}
