/* eslint-disable */
export interface FirebaseConfiguration {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

export interface IOSConfiguration {
  displayName: string;
  bundleId: string;
  teamId: string;
  iosAppStoreId: string,
  firebaseApiKey: string;
  firebaseAppId: string;
  firebaseClientId: string;
  codePushDeploymentKey: string;
}

export interface AndroidConfiguration {
  displayName: string;
  packageName: string;
  firebaseAppId: string;
  firebaseClientId: string;
  firebaseApiKey: string;
  codePushDeploymentKey: string;
}

export interface EnvironmentConfiguration {
  built: number;
  appVersion: string;
  eulaVersion: string;
  environment: string;
  production: boolean;
  bcc: string;
  sendgridFrom: string;
  subjectPrefix: string;
  siteOrigin: string;
  siteOriginWww: string;
  cloudFunctionOrigin: string;
  dynamicLinkOrigin: string;
  appengineOrigin: string;
  gcpLocation: string;
  firebase: FirebaseConfiguration;
  ios: IOSConfiguration;
  android: AndroidConfiguration;
}


export const environment: Partial<EnvironmentConfiguration> = ({
  built: 1640323003316,
  appVersion: '1.4.11',
  eulaVersion: '1f8a84c73c9bce6c9a06aca496ad1ab11939e4d1',
  environment: 'prod',
  siteOriginWww: 'https://www.seeyamobile.com',
  appengineOrigin: 'https://doorcaptain-staging.uc.r.appspot.com',
  dynamicLinkOrigin: 'https://www.seeyamobile.com/links',
  gcpLocation: 'us-central1',
  sendgridFrom: 'notifications@seeyamobile.com',
  production: true,
  bcc: 'dario@opstrax.com',
  subjectPrefix: '',
  siteOrigin: 'https://business.seeyamobile.com',
  firebase: {
    apiKey: 'AIzaSyCY_dS_6p1V7Q8DKDo4i_SY2DCLgN9xbec',
    authDomain: 'seeya-prod.firebaseapp.com',
    databaseURL: 'https://seeya-prod-default-rtdb.firebaseio.com',
    projectId: 'seeya-prod',
    storageBucket: 'seeya-prod.appspot.com',
    messagingSenderId: '801177442109',
    appId: '1:801177442109:web:9276d30aac19847fef1754',
    measurementId: 'G-F8FY001CT3'
  },
  ios: {
    displayName: 'SeeYa! Mobile',
    bundleId: 'com.ops.SeeYa',
    teamId: '5QGN896X23',
    iosAppStoreId: '1562710967',
    provisionalProfile: 'SeeYa Prod Distribution',
    firebaseApiKey: 'AIzaSyBJD6EePc0tUTteJPYe2_fLVYFKi-BjK38',
    firebaseAppId: '1:801177442109:ios:f26d0edec6eb43c3ef1754',
    firebaseClientId: '801177442109-v5200rck9pfasqlkcftga33p0gmmv1iq.apps.googleusercontent.com',
    codePushDeploymentKey: 'ab2oJ8lKgY0BFm3fHNIfZhYqB79aT0P5buxBq'
  },
  android: {
    displayName: 'SeeYa! Mobile',
    packageName: 'com.ops.SeeYa',
    firebaseAppId: '1:801177442109:android:738d294e9c8c2129ef1754',
    firebaseClientId: '801177442109-ja997igjmttmr223s0c29koalankin5i.apps.googleusercontent.com',
    firebaseApiKey: 'AIzaSyDzV11-ZpA4ii21qYlN21g8NJL65cFqb4U',
    codePushDeploymentKey: 'ooC3ZJ8JSVW4tNAdRXTT0Mpc4GwtFQL_EvNh7'
  },
  cloudFunctionOrigin: 'https://business.seeyamobile.com'
} as Partial<EnvironmentConfiguration>);
