import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {combineLatest, Observable, of as observableOf} from 'rxjs';
import firebase from 'firebase/app';
import {AngularFireAuth} from '@angular/fire/auth';
import {UserProfile, UserPublicData} from '@appFunctions/users/types';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserProxyService {
  constructor(private readonly afs: AngularFirestore,
    private readonly fbAuth: AngularFireAuth) {
  }

  doc(id: string): Observable<Partial<UserPublicData> | undefined> {
    if (!id) {
      return observableOf(undefined);
    }
    return this.afs.collection(`/modules/doorcaptain/users`).doc<Partial<UserPublicData>>(id).valueChanges();
  }

  docWithPii(id: string): Observable<Partial<UserProfile> | undefined> {
    if (!id) {
      return observableOf(undefined);
    }
    return combineLatest([
      this.doc(id),
      this.afs.doc<Partial<UserProfile>>(
        `modules/doorcaptain/users/${id}/users-private-collection-group/data`).valueChanges()
    ]).pipe(
      map(([userPublicData, userPrivateData]) => ({...userPublicData, ...userPrivateData})),
    );
  }

  async createPublicProfile(uid: string, noPii: Partial<{
    displayName: string;
    photoURL: string;
  }>): Promise<void> {
    const model = {
      id: uid,
      ...noPii,
      createdBy: (await this.fbAuth.currentUser)?.uid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    };
    await this.afs.doc(`/modules/doorcaptain/users/${uid}`).set(model, {merge: true});
  }

  async updatePublicProfile(uid: string, noPii: Partial<{
    displayName: string;
    photoURL: string;
  }>): Promise<void> {
    const model = {
      ...noPii,
      updatedBy: (await this.fbAuth.currentUser)?.uid,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    };
    await this.afs.doc(`/modules/doorcaptain/users/${uid}`).update(model);
  }

  async createPrivateProfile(uid: string, profile: Partial<{
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    totalLocations: string;
  }>): Promise<void> {
    await this.afs.doc(`/modules/doorcaptain/users/${uid}/users-private-collection-group/data`).set({
      id: uid,
      ...profile,
      createdBy: uid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    }, {merge: true});
  }

  async updatePrivateProfile(uid: string, profile: Partial<{
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    totalLocations: string;
  }>): Promise<void> {
    await this.afs.doc(`/modules/doorcaptain/users/${uid}/users-private-collection-group/data`).set({
      ...profile,
      updatedBy: uid,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    }, {merge: true});
  }
}
